import { Hidden } from '@material-ui/core';
import React from 'react';
import ImgBanner from 'src/images/campaign/multiple/banner.png';
import ImgBannerSp from 'src/images/campaign/multiple/banner_sp.png';
import ImgPaidyLogo from 'src/images/logo-color-header.png';
import styles from './TopBanner.module.scss';

export default function TopBanner() {
  return (
    <section className={styles.section}>
      <Hidden xsDown>
        <div className={styles.left}>
          <img alt="paidy-logo" src={ImgPaidyLogo} width={140} height={40} />
          <h1 className={styles.head}>
            分割手数料無料*の３回あと払いで 必要なものをかしこく手に入れて、
            あなたの毎日をもっとハッピーに。
          </h1>
          <p className={styles.text}>
            分割手数料無料*でお支払いを３回に分けられる、ペイディの３回あと払い。どうしても必要なものも、かしこく計画的に購入できます。新しいアイテムと一緒に、もっと素敵でハッピーな毎日を。
          </p>
          <p className={styles.notes}>*口座振替・銀行振込のみ無料</p>
        </div>
        <img alt="banner" src={ImgBanner} width={612.32} height={526} />
      </Hidden>
      <Hidden smUp>
        <img alt="banner" src={ImgBannerSp} width="100%" />
        <div className={styles.texts}>
          <h1 className={styles.head}>
            分割手数料無料*の３回あと払いで
            <br />
            必要なものをかしこく手に入れて、
            <br />
            あなたの毎日をもっとハッピーに。
          </h1>
          <p className={styles.text}>
            分割手数料無料*でお支払いを３回に分けられる、ペイディの３回あと払い。どうしても必要なものも、かしこく計画的に購入できます。新しいアイテムと一緒に、もっと素敵でハッピーな毎日を。
          </p>
          <p className={styles.notes}>*口座振替・銀行振込のみ無料</p>
        </div>
      </Hidden>
    </section>
  );
}
