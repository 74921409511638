export const MERCHANTS = [
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-buyma.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-buyma.png'),
    merchantName: 'BUYMA',
    destinationURL: 'https://www.buyma.com/',
    content: '世界を買える 海外ブランド・ファッション通販「BUYMA(バイマ)」。',
    category: [2, 3, 7],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-0.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-0.png'),
    merchantName: 'WILLER TRAVEL',
    destinationURL:
      'https://travel.willer.co.jp/?mid=1458&utm_source=au&utm_medium=referral',
    content: '日本全国をお得に移動できる安心安全な高速バス予約サイトです。',
    category: [4],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-1.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-1.png'),
    merchantName: 'URBAN RESEARCH ONLINE STORE',
    destinationURL: 'https://www.urban-research.jp/',
    content: 'アーバンリサーチの人気ブランドを取り揃えたオンラインショップ',
    category: [2, 3, 4],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-2.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-2.png'),
    merchantName: 'ニューバランス公式オンラインストア',
    destinationURL: 'https://shop.newbalance.jp/shop/',
    content: 'ニューバランスの≪公式≫オンラインストア',
    category: [2, 7],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-francfranc.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-francfranc.png'),
    merchantName: 'Francfranc ONLINE SHOP',
    destinationURL: 'https://francfranc.com/',
    content: '多彩なアイテムで、心高鳴る毎日をお届けします。',
    category: [3],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-3.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-3.png'),
    merchantName: 'さくらトラベル',
    destinationURL: 'https://www.sakuratravel.jp/',
    content: '一番安い航空券がスグ見つかります！当日便予約も最大48％オフ',
    category: [4],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-4.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-4.png'),
    merchantName: 'エアトリ(AirTrip)',
    destinationURL:
      'https://www.airtrip.jp/?utm_source=paidy.com&utm_medium=referral&utm_campaign=paidy_campaign_from20221101',
    content: '格安航空券・国内の航空会社13社の最安値一括比較検索が可能！',
    category: [4],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-5.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-5.png'),
    merchantName: '【コスメ・雑貨】ポール & ジョー公式オンラインストア',
    destinationURL: 'https://www.paul-joe-beaute.com/pages/hair-care-goods',
    content: 'ギフトにもぴったりなコスメと雑貨を集めたブランド公式サイト',
    category: [2, 1],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-6.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-6.png'),
    merchantName: 'サウンドハウス',
    destinationURL: 'https://www.soundhouse.co.jp/',
    content: '楽器、音響・照明機器などを扱う国内最大級のECサイトです。',
    category: [3, 5, 7],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-7.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-7.png'),
    merchantName: 'atmos',
    destinationURL: 'https://www.atmos-tokyo.com/',
    content: 'ナイキやアディダスなどの人気スニーカーを多数揃えています。',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-8.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-8.png'),
    merchantName: '高速バスドットコム',
    destinationURL: 'https://www.kosokubus.com/?ad=PDY',
    content: '日本全国の高速バス比較・予約サイト！取扱い便数は日本最大級！',
    category: [4, 7],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-9.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-9.png'),
    merchantName: 'RASIK(ラシク)',
    destinationURL: 'https://rasik.style/',
    content: 'インテリア家具専門店。ジブンらしい家づくりをお手伝いします。',
    category: [3, 5, 7],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-aimerfeel.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-aimerfeel.png'),
    merchantName: 'aimerfeel公式通販サイト',
    destinationURL: 'https://shop.aimerfeel.jp/shop/default.aspx',
    content: 'はじめての方は返品送料0円！可愛い＆セクシーな下着ブランド',
    category: [2, 7],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-10.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-10.png'),
    merchantName: '夢展望',
    destinationURL: 'https://dreamvs.jp/',
    content: '産・地雷系・ギャル系など色々なテイストの服が豊富にあります。',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-11.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-11.png'),
    merchantName: 'WEGO ONLINE STORE',
    destinationURL: 'https://gocart.jp/pages/wego',
    content:
      'WEGO（ウィゴー）公式通販。ほしいが見つかる1. ファッションストア！Z世代人気No.１サイト。',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-60percent.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-60percent.png'),
    merchantName: 'SIXTYPERCENT',
    destinationURL:
      'https://www.sixty-percent.com/?utm_source=paidy&utm_medium=referral&utm_campaign=lp',
    content: 'アジアのブランドを集めたオンラインセレクトストア',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-12.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-12.png'),
    merchantName: '5-fifth.com',
    destinationURL:
      'https://5-fifth.com/?utm_source=paidy_member&utm_medium=paidy&utm_term=20221215',
    content: 'ハイビジュアル・低価格で洋服、靴、アクセを提供する通販サイト',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-13.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-13.png'),
    merchantName: 'UNDER ARMOUR(アンダーアーマー)',
    destinationURL: 'https://www.underarmour.co.jp/top/CSfTop.jsp',
    content:
      'アンダーアーマー公式オンラインストア\nアスリートを進化させる革新的スポーツウェア',
    category: [2, 4],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-14.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-14.png'),
    merchantName: 'nairo department',
    destinationURL: 'https://nairo.jp/',
    content: '『シンプルなのに、どこかお洒落』がコンセプトのD2Cブランド',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-15.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-15.png'),
    merchantName: 'タンスのゲン本店',
    destinationURL: 'https://www.tansu-gen.jp/',
    content: '家具・寝具・インテリアの総合通販｜【公式】タンスのゲン本店',
    category: [3, 5],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-16.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-16.png'),
    merchantName: 'GREEN FUNDING',
    destinationURL:
      'https://greenfunding.jp/?utm_campaign=paidy_202211&utm_source=paidy&utm_medium=GREENFUNDING',
    content: '未来を先取りしたガジェットが集まるクラウドファンディングです',
    category: [3, 4, 5],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-17.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-17.png'),
    merchantName: 'WithLIVE',
    destinationURL: 'https://www.withlive.jp/',
    content: 'スマホで手軽にオンライン特典会が楽しめるトークアプリです。',
    category: [6, 7],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-18.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-18.png'),
    merchantName: 'ツクモネットショップ',
    destinationURL: 'https://shop.tsukumo.co.jp/',
    content: 'パソコン、PCパーツ、VR機器などを扱うPCショップです。',
    category: [3],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-esica.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-esica.png'),
    merchantName: 'ESICA',
    destinationURL: 'https://esica.shop/',
    content: '人気のスタイリングがセットでお得にご購入いただけます。',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-19.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-19.png'),
    merchantName: 'EDWIN公式オンラインショップ',
    destinationURL: 'https://edwin-mall.jp/',
    content: 'デニム・ジーンズ通販EDWIN（エドウイン）オンラインモール',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-20.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-20.png'),
    merchantName: 'Proactiv',
    destinationURL:
      'https://proactiv.jp/paspap/entrance.aspx?siteid=paadsf315&reid=1972948449',
    content: '日本人の肌のために、プロアクティブが新しくなりました。',
    category: [1],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-21.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-21.png'),
    merchantName: 'PUNYUS',
    destinationURL: 'https://punyus.jp/',
    content:
      '渡辺直美プロデュースブランド サイズ展開やオリジナルプリントが豊富！',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-22.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-22.png'),
    merchantName: '洋服の青山オンラインストア',
    destinationURL: 'https://www.y-aoyama.jp/',
    content: 'メンズ・レディースのビジネスウェアを買うなら洋服の青山',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-23.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-23.png'),
    merchantName: 'コジマネット',
    destinationURL: 'https://www.kojima.net/ec/index.html',
    content: '人気家電がコジマネット価格でお買い得！安心の長期保証無料進呈',
    category: [1, 3, 7],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-24.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-24.png'),
    merchantName: 'AXES(アクセス) 海外ブランド通販',
    destinationURL:
      'https://www.axes-net.com?utm_source=paidy&utm_medium=referral&utm_campaign=paidystorepage',
    content:
      'はじめてでも安心。30日間返品保証！海外ブランド通販でお得にお買い物',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-25.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-25.png'),
    merchantName: 'スーツスクエア オンラインストア',
    destinationURL: 'https://www.uktsc.com/',
    content: '高い品質とリーズナブルな価格で商品をご用意しております。',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-26.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-26.png'),
    merchantName: 'ニューバランス公式オンラインアウトレット',
    destinationURL: 'https://outlet.newbalance.jp/shop/',
    content: 'ニューバランスの≪公式≫オンラインアウトレット',
    category: [2, 7],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-jcation.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-jcation.png'),
    merchantName: 'Jcation',
    destinationURL: 'https://jcation.com/tour/',
    content: '日本全国のパックツアーやレンタカー料金を一括検索、簡単予約。',
    category: [4],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-cocotteparis.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-cocotteparis.png'),
    merchantName: 'Soir,5 COCOTTE PARIS',
    destinationURL: 'https://soir5cocotteparis.com/',
    content: 'トレンド感のあるSimple、Elegant、Casualなアイテムを提案します',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-28.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-28.png'),
    merchantName: 'OPPO公式オンラインショップ',
    destinationURL: 'https://shop.oppojapan.com/',
    content: '世界50以上の国と地域で愛されるスマートデバイスメーカーです',
    category: [6, 7],
  },
  {
    imgBg: require('src/images/campaign/multiple/merchants/banners/banner-29.png'),
    imgLogo: require('src/images/campaign/multiple/merchants/logos/logo-29.png'),
    merchantName: 'MELE MELE',
    destinationURL: 'https://melemele.jp/',
    content: '天然ダイヤを使っているジュエリーブランドのオンラインショップ',
    category: [2, 3, 7],
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: '美容・健康' },
  { value: 2, label: 'ファッション' },
  { value: 3, label: '家電・インテリア' },
  { value: 4, label: 'トラベル・スポーツ' },
  { value: 5, label: '総合通販' },
  { value: 6, label: 'デジタルコンテンツ' },
  { value: 7, label: 'その他' },
];
