import React from 'react';
import Img3PayUpSell from 'src/images/campaign/multiple/3pay_upsell.png';
import MerchantGrid from './MerchantGrid';
import styles from './Promotion.module.scss';

export default function Promotion() {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.left}>
            <h1 className={styles.head}>
              おすすめのお店なら
              <br />
              月々の支払いがひと目でわかる
            </h1>
            <p className={styles.text}>
              今回ご紹介するおすすめのお店なら、商品ページで月々のお支払い金額をひと目で確認。電卓を片手に、面倒な計算をする時間とはもうサヨナラです。
            </p>
          </div>
          <img
            alt="3pay_upsell"
            src={Img3PayUpSell}
            width={456}
            height={496}
            className={styles.img}
          />
        </div>
        <MerchantGrid />
      </div>
    </section>
  );
}
