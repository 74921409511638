import React from 'react';
import useAppURLsByDevice from 'src/hooks/useAppUrlsByDevice';
import HorizontalScrollNavigation from 'src/components/Guide/HorizontalScrollNavigation';
import LinearCTAButton from 'src/components/LinearCTAButton';
import styles from './ThreePay.module.scss';
import { trackAppDownload } from '../../../utils';

const SP_URLS = {
  appStoreUrl: 'https://paidy.onelink.me/GTiS/guide',
  playStoreUrl: 'https://paidy.onelink.me/GTiS/guide',
};

export default function MultipleThreePay() {
  const ThreePayProcessItems = [
    {
      ImgComponent: () => {
        const { appStoreUrl, playStoreUrl } = useAppURLsByDevice(SP_URLS);

        return (
          <div className={styles.appIconContainer}>
            <a
              title="Paidy | App Store"
              className={styles.appIcon}
              href={appStoreUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => trackAppDownload('App Store', 'multiple_3pay')}
            >
              <img
                src={require('src/images/badge-app-store.svg')}
                alt="App Storeのアイコン"
                width="184"
              />
            </a>
            <a
              title="Paidy | Google Play"
              className={styles.appIcon}
              href={playStoreUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => trackAppDownload('Google Play', 'multiple_3pay')}
            >
              <img
                src={require('src/images/badge-google-play.svg')}
                alt="Google Playのアイコン"
                width="184"
              />
            </a>
          </div>
        );
      },
      figCaption: 'ペイディアプリから本人確認をする。',
    },
    {
      ImgComponent: () => (
        <img
          src={require('src/images/guide-3pay-1.svg')}
          alt={'ショッピングカートのアイコン'}
        />
      ),
      figCaption: 'ショップで欲しい商品をカートに入れる。',
    },
    {
      ImgComponent: () => (
        <img
          src={require('src/images/guide-3pay-2.svg')}
          alt={
            'ペイディアプリのメールアドレス、携帯電話番号の入力フォームの画像'
          }
        />
      ),
      figCaption:
        'お支払い方法で「あと払い（ペイディ）」を選択して、メアドと携帯番号を入力する。',
    },
    {
      ImgComponent: () => (
        <img
          src={require('src/images/guide-3pay-3b.svg')}
          alt={'３回あと払いのボタンの画像'}
        />
      ),
      figCaption: 'お支払い回数で「３回あと払い」を選んで、お買い物完了！',
    },
  ];

  return (
    <section className={styles.section}>
      <h1 className={styles.head}>３回あと払いの使い方はとってもかんたん</h1>
      <HorizontalScrollNavigation
        items={ThreePayProcessItems}
        bgContainer={styles.scrollContainer}
        noDots
        horizontalScrollContainer={styles.horizontalScrollContainer}
      />
      <LinearCTAButton
        text="３回あと払いの詳細を見る"
        to="https://paidy.com/landing/plus_3pay/"
        isExternal
        className={styles.btn}
      />
    </section>
  );
}
