import React from 'react';
import { Layout } from 'src/components';
import ThreePay from 'src/components/Merchant/Multiple/ThreePay';
import PayLayter from 'src/components/Merchant/Multiple/PayLater';
import TopBanner from 'src/components/Merchant/Multiple/TopBanner';
import Promotion from 'src/components/Merchant/Multiple/Promotion';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/Newlife/SectionCampaignFooter';
import { SITE_METADATA } from 'src/constants';

const SEOProps = {
  title: SITE_METADATA.multiple.title,
  description: SITE_METADATA.multiple.description,
};

export default function Multiple() {
  return (
    <Layout SEOProps={SEOProps} hasNoFooter hasNoHeader>
      <TopBanner />
      <PayLayter />
      <ThreePay />
      <Promotion />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}
