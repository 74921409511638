import React from 'react';
import ImgWhyPaidy1 from 'src/images/merchant-multiple-why-paidy.png';
import styles from './PayLater.module.scss';

export default function PayLayter() {
  return (
    <section className={styles.section}>
      <h1 className={styles.head}>
        例えば、30,000円の商品なら…
        <br />
        10,000円ずつ、３か月でお支払い！
      </h1>
      <img
        alt="pay-later"
        src={ImgWhyPaidy1}
        width={400}
        className={styles.img}
      />
    </section>
  );
}
